<template>
  <div class="input-vials">
    <Btn height="s" type="primary" icon-left="play_arrow" :disabled="isDisabled" @click="showPopup">
      <slot>Single injection</slot>
    </Btn>

    <BottomSheet :isShow="isShowPopup" title="Select vial" @hide="hidePopup">
      <TrayVials
        checkedVial=""
        :isInPopup="true"
        :config="trayConfig"
        class="input-vials__tray"
        @model="setVial"
      />
    </BottomSheet>
  </div>
</template>

<script>
  import TrayVials from '../../vueTrayVials/TrayVials';
  import testDirective from '@/directives/test';
  import { props, EVENT_SELECT } from '../shared/config.ts';
  import BottomSheet from '@/uikitBase/popups/BottomSheet';
  import Btn from '@/uikitBase/btns/Btn';

  export default {
    name: 'MobileBtnSelectVial',

    components: {
      Btn,
      BottomSheet,
      TrayVials,
    },

    directives: { test: testDirective },

    props,

    data: () => ({
      isShowPopup: false,
    }),

    computed: {
      _vial: {
        get() {
          return this.vial;
        },
        set(value) {
          this.$emit(EVENT_SELECT, value && value.toUpperCase());
          this.hidePopup();
        },
      },
    },

    methods: {
      showPopup() {
        this.isShowPopup = true;
      },
      hidePopup() {
        this.isShowPopup = false;
      },

      setVial(vial) {
        this.$emit(EVENT_SELECT, vial.toUpperCase());
        this.hidePopup();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .input-vials {
    display: flex;

    &__tray {
      margin-top: 30px;
      margin-left: -14px;
    }
  }
</style>
